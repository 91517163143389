import React from 'react'
import { WeightMeasurement, DecodedClient } from 'logr-air-lib'

export const NEW_SESSION = 'NEW_SESSION'
export const UPDATE_CONTROL_API_KEY = 'UPDATE_CONTROL_API_KEY'
export const UPDATE_CAMERA_API_KEY = 'UPDATE_CAMERA_API_KEY'
export const UPDATE_DRIVER_API_KEY = 'UPDATE_DRIVER_API_KEY'
export const UPDATE_DALLAS_KEY_API_KEY = 'UPDATE_DALLAS_KEY_API_KEY'
export const UPDATE_WEIGHBRIDGE_UID = 'UPDATE_WEIGHBRIDGE_UID'
export const UPDATE_NUMBER_PLATE = 'UPDATE_NUMBER_PLATE'
export const UPDATE_CONTROL_WEIGHT = 'UPDATE_CONTROL_WEIGHT'
export const UPDATE_ENVIRONMENT = 'UPDATE_ENVIRONMENT'
export const UPDATE_URL = 'UPDATE_URL'
export const SIGN_IN_DRIVER = 'SIGN_IN_DRIVER'
export const SIGN_OUT_DRIVER = 'SIGN_OUT_DRIVER'
export const DRIVER_TOKEN = 'DRIVER_TOKEN'
export const SIGN_IN_USER = 'SIGN_IN_USER'
export const TOGGLE_SHOW_WEIGHBRIDGE = 'TOGGLE_SHOW_WEIGHBRIDGE'
export const TOGGLE_SHOW_DRIVER_APP = 'TOGGLE_SHOW_DRIVER_APP'
export const TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE = 'TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE'
export const UPDATE_DALLAS_KEY_NUMBER_PLATE = 'UPDATE_DALLAS_KEY_NUMBER_PLATE'

export enum Uid {
  iWeigh = 'dDv4U0iok5OWbpxm4pXSVqC1O4E2',
  visy = 'RBuVENDqdZPiXPYbE33PYwSzdH83',
  enabledUid = '9fcWnnnNTyXAel1wwJIxnIn2dR72',
}

export enum WeighbridgeUid {
  iWeigh = 'TEST_IWEIGH_WEIGHBRIDGE_1',
  visy1 = 'TEST_VISY_WEIGHBRIDGE_1',
  visy2 = 'TEST_VISY_WEIGHBRIDGE_2',
  enabledUid = 'INTERNAL_CONTROL_SOFTWARE',
}

export type GlobalStateActionTypes = { type: typeof NEW_SESSION }
  | { type: typeof UPDATE_CONTROL_API_KEY, data: string }
  | { type: typeof UPDATE_CAMERA_API_KEY, data: string }
  | { type: typeof UPDATE_DRIVER_API_KEY, data: string }
  | { type: typeof UPDATE_DALLAS_KEY_API_KEY, data: string }
  | { type: typeof UPDATE_WEIGHBRIDGE_UID, data: string }
  | { type: typeof UPDATE_NUMBER_PLATE, data: string }
  | { type: typeof UPDATE_CONTROL_WEIGHT, data: WeightMeasurement }
  | { type: typeof UPDATE_ENVIRONMENT, data: 'local' | 'staging' | 'production' }
  | { type: typeof SIGN_IN_DRIVER, data: firebase.User }
  | { type: typeof SIGN_OUT_DRIVER }
  | { type: typeof DRIVER_TOKEN, data: string }
  | { type: typeof SIGN_IN_USER, data: { token: string, client: DecodedClient } }
  | { type: typeof TOGGLE_SHOW_WEIGHBRIDGE }
  | { type: typeof TOGGLE_SHOW_DRIVER_APP }
  | { type: typeof TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE }
  | { type: typeof UPDATE_DALLAS_KEY_NUMBER_PLATE, data: string }

// const URL = "wss://staging.session.air.logr.com.au"
// const URL = "wss://session.air.logr.com.au"
export const AvailableEnvironments: { id: 'local' | 'staging' | 'production', name: string, url: string, secret?: string }[] = [
  // { id: 'local', url: 'ws://localhost:8080', name: 'Local', secret: 'secret' },
  { id: 'staging', url: 'wss://staging.session.air.logr.com.au', name: 'Staging' },
  { id: 'production', url: 'wss://session.air.logr.com.au', name: 'Production' },
]

export type GlobalState = {
  weighbridgeUid?: string
  host: string
  environment: 'local' | 'staging' | 'production',
  weighbridge: {
    show: boolean
    apiKey?: string
    measurements: WeightMeasurement[]
  }
  driverApp: {
    show: boolean
    apiKey?: string
    user?: firebase.User
    token?: string
  }
  camera: {
    apiKey?: string
    numberPlate?: string
  },
  dallasKey: {
    show: boolean
    apiKey?: string
    numberPlate?: string
  }
  weighbridgeState: WeighbridgeState
  driverAppState: DriverAppState
}

const getInitialStateForEnvironment = (env?: 'local' | 'staging' | 'production'): GlobalState => {
  const measurementsFromLocalStorage = localStorage.getItem(env + UPDATE_CONTROL_WEIGHT)

  if (!env) {
    env = (localStorage.getItem(UPDATE_ENVIRONMENT) || 'local') as 'local' | 'staging' | 'production'
  }

  return {
    weighbridgeUid: localStorage.getItem(env + UPDATE_WEIGHBRIDGE_UID) || undefined,
    host: localStorage.getItem(env + UPDATE_URL) || AvailableEnvironments.find(item => item.id === env)?.url || '',
    environment: env,
    weighbridge: {
      show: (localStorage.getItem(env + TOGGLE_SHOW_WEIGHBRIDGE) || 'true') === 'true',
      apiKey: localStorage.getItem(env + UPDATE_CONTROL_API_KEY) || undefined,
      measurements: measurementsFromLocalStorage ? JSON.parse(measurementsFromLocalStorage) : undefined
    },
    camera: {
      apiKey: localStorage.getItem(env + UPDATE_CAMERA_API_KEY) || undefined,
      numberPlate: localStorage.getItem(env + UPDATE_NUMBER_PLATE) || undefined,
    },
    driverApp: {
      show: (localStorage.getItem(env + TOGGLE_SHOW_DRIVER_APP) || 'true') === 'true',
      apiKey: localStorage.getItem(env + UPDATE_DRIVER_API_KEY) || undefined,
    },
    dallasKey: {
      show: (localStorage.getItem(env + TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE) || 'true') === 'true',
      apiKey: localStorage.getItem(env + UPDATE_DALLAS_KEY_API_KEY) || undefined,
      numberPlate: localStorage.getItem(env + UPDATE_DALLAS_KEY_NUMBER_PLATE) || undefined,
    },
    weighbridgeState: {},
    driverAppState: {},
  }
}

export const initialState: GlobalState = getInitialStateForEnvironment()

type GlobalContext = {
  state: GlobalState
  dispatch: (action: GlobalStateActionTypes) => void
}

export const GlobalContext = React.createContext<GlobalContext>({
  state: initialState,
  dispatch: (action: GlobalStateActionTypes) => { },
})

const localStorageReducer = (state: GlobalState, action: GlobalStateActionTypes): GlobalState => {
  const storageKeys = [
    UPDATE_CONTROL_API_KEY,
    UPDATE_CAMERA_API_KEY,
    UPDATE_DRIVER_API_KEY,
    UPDATE_DALLAS_KEY_API_KEY,
    UPDATE_WEIGHBRIDGE_UID,
    UPDATE_NUMBER_PLATE,
    UPDATE_DALLAS_KEY_NUMBER_PLATE,
    UPDATE_CONTROL_WEIGHT,
    UPDATE_ENVIRONMENT,
    TOGGLE_SHOW_WEIGHBRIDGE,
    TOGGLE_SHOW_DRIVER_APP,
    TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE,
  ]

  if (action.type === 'SIGN_IN_USER') {
    localStorage.setItem(state.environment + UPDATE_CONTROL_API_KEY, action.data.token)
  }

  if (storageKeys.includes(action.type)) {
    const value = (action as any).data

    if (typeof value === 'string') {
      if (action.type === UPDATE_ENVIRONMENT) {
        localStorage.setItem(action.type, value)
      } else {
        localStorage.setItem(state.environment + action.type, value)
      }
    }
  }

  return state
}

const mainReducer = (state: GlobalState, action: GlobalStateActionTypes): GlobalState => {
  switch (action.type) {
    case NEW_SESSION:
      return { ...initialState }

    case SIGN_IN_USER:
      return { ...state, weighbridge: { ...state.weighbridge, apiKey: action.data.token } }

    case UPDATE_CONTROL_API_KEY:
      return { ...state, weighbridge: { ...state.weighbridge, apiKey: action.data } }

    case UPDATE_CAMERA_API_KEY:
      return { ...state, camera: { ...state.camera, apiKey: action.data } }

    case UPDATE_DRIVER_API_KEY:
      return { ...state, driverApp: { ...state.driverApp, apiKey: action.data } }
    
    case UPDATE_DALLAS_KEY_API_KEY:
      return { ...state, dallasKey: { ...state.dallasKey, apiKey: action.data } }

    case UPDATE_WEIGHBRIDGE_UID:
      return { ...state, weighbridgeUid: action.data }

    case UPDATE_NUMBER_PLATE:
      return { ...state, camera: { ...state.camera, numberPlate: action.data } }

    case UPDATE_DALLAS_KEY_NUMBER_PLATE:
      return { ...state, dallasKey: { ...state.dallasKey, numberPlate: action.data } }

    case UPDATE_CONTROL_WEIGHT:
      return { ...state, weighbridge: { ...state.weighbridge, measurements: [...state.weighbridge.measurements.filter(item => item.type !== action.data.type), action.data] } }

    case UPDATE_ENVIRONMENT:
      return { ...getInitialStateForEnvironment(action.data), environment: action.data }

    case SIGN_IN_DRIVER:
      return { ...state, driverApp: { ...state.driverApp, user: action.data } }

    case SIGN_OUT_DRIVER:
      return { ...state, driverApp: { ...state.driverApp, user: undefined } }

    case DRIVER_TOKEN:
      return { ...state, driverApp: { ...state.driverApp, token: action.data } }

    case TOGGLE_SHOW_WEIGHBRIDGE:
      localStorage.setItem(state.environment + action.type, JSON.stringify(!state.weighbridge.show))
      return { ...state, weighbridge: { ...state.weighbridge, show: !state.weighbridge.show } }

    case TOGGLE_SHOW_DRIVER_APP:
      localStorage.setItem(state.environment + action.type, JSON.stringify(!state.driverApp.show))
      return { ...state, driverApp: { ...state.driverApp, show: !state.driverApp.show } }

    case TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE:
      localStorage.setItem(state.environment + action.type, JSON.stringify(!state.dallasKey.show))
      return { ...state, dallasKey: { ...state.dallasKey, show: !state.dallasKey.show } }
  }

  return state
}

type WeighbridgeState = {}
const weighbridgeReducer = (state: WeighbridgeState, action: GlobalStateActionTypes): WeighbridgeState => {
  // TODO: Move state out of components and into here
  return state
}

type DriverAppState = {}
const driverAppReducer = (state: WeighbridgeState, action: GlobalStateActionTypes): WeighbridgeState => {
  // TODO: Move state out of components and into here
  return state
}

export const reducer = (state: GlobalState, action: GlobalStateActionTypes): GlobalState => {
  const reducers = [
    localStorageReducer,
    mainReducer,
  ]

  const newState = reducers.reduce((prev, next) => next(prev, action), state)

  return {
    ...newState,
    weighbridgeState: weighbridgeReducer(newState.weighbridgeState, action),
    driverAppState: driverAppReducer(newState.driverAppState, action),
  }
}
