import React from 'react'
import { GlobalContext } from '../state/reducer'
import useAirSocket, { AirSocketStatus, AirServerEvent } from '../hooks/useAirSocket'
import { AirChannel, DriverChannel, DriverClaimed, ClaimBecameAvailable, AirAuthChannel, RequestedWeightAndIdentityConfirmation, DriverClaimAndIdentityConfirmed } from 'logr-air-lib'
import ApiKeyStatus from './ApiKeyStatus'
import moment from 'moment'
import { PrettyLogItem, GenericAction } from '../state/types'
import DocketCard, { initialDocketState } from './DocketCard'
import PrettyLogs from './PrettyLogs'
import uuid from 'uuid'

type DriverAppState = {
  authenticated: boolean
  claim?: ClaimBecameAvailable
  confirm?: RequestedWeightAndIdentityConfirmation
}
const reducer = (state: DriverAppState, next: AirServerEvent): DriverAppState => {
  switch (next?.type) {
    case AirChannel.claim_became_available:
      return {
        ...state,
        claim: next.data,
        confirm: undefined
      }

    case AirChannel.requested_driver_confirmation:
      return {
        ...state,
        claim: undefined,
        confirm: next.data
      }

    case AirAuthChannel.authenticate_confirmed:
      return {
        ...state,
        authenticated: true
      }
  }

  return state
}

const DriverApp = () => {
  const { state } = React.useContext(GlobalContext)
  const { apiKey } = state.driverApp
  const { weighbridgeUid, host } = state
  const url = [host, weighbridgeUid].join('/')
  const [sendMessage, lastEvent, readyState, disconnect, reconnect, logs, version, socketError] = useAirSocket(url, apiKey)
  const [activeDocket, setActiveDocket] = React.useState(initialDocketState)

  const [eventState, dispatchEvent] = React.useReducer(reducer, { authenticated: false })
  const { claim, confirm } = eventState
  React.useEffect(() => lastEvent && dispatchEvent(lastEvent), [lastEvent])

  const [prettyLogs, addPrettyLog] = React.useReducer((state: PrettyLogItem[], action: GenericAction | null): PrettyLogItem[] => action === null ? [] : [{ iat: Date.now(), evt: action }, ...state], [])
  React.useEffect(() => lastEvent && addPrettyLog(lastEvent), [lastEvent])

  const onClaim = React.useCallback(() => {
    if (claim) {
      const data: DriverClaimed = { claim_id: claim.claim_id }
      const payload = { type: DriverChannel.driver_claimed, data: data }
      addPrettyLog(payload)
      sendMessage(payload)
    }
  }, [claim, sendMessage])

  const onConfirm = React.useCallback(() => {
    if (confirm) {

      const data: DriverClaimAndIdentityConfirmed = {
        ...confirm,
        ...activeDocket,
        guid: 'guid-' + activeDocket.docket_id, // This is meant to be a UUID
        confirmed_at_timestamp: moment().format(),
        weight_attribution_order: ['gross', 'tare']
      }

      const payload = { type: DriverChannel.driver_confirmed, data: data }
      addPrettyLog(payload)
      sendMessage(payload)
    }
  }, [confirm, sendMessage, activeDocket])

  return (
    <div className="DriverApp">
      <h3>Driver App</h3>

      <div className="TagsContainer">
        <AirSocketStatus status={readyState} />
        <ApiKeyStatus apiKey={apiKey} />
      </div>

      {readyState === WebSocket.OPEN && <DocketCard onChange={setActiveDocket} enabled={!confirm} />}

      {claim && readyState === WebSocket.OPEN && <button className="button" onClick={onClaim}>Claim</button>}
      {confirm
        && readyState === WebSocket.OPEN
        && !prettyLogs.find(item => item.evt.type === DriverChannel.driver_confirmed)
        && <button className="button" onClick={onConfirm}>Confirm</button>}

      <PrettyLogs logs={prettyLogs}/>

      <div style={{ marginTop: '1ch' }}>
        {/* {readyState === WebSocket.OPEN && <button className="button" onClick={reconnect}>Reconnect</button>} */}
      </div>
    </div>
  )
}

export default DriverApp