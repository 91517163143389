import jwt from 'jwt-js'
import {
  isDecodedClient, DecodedClient
} from 'logr-air-lib'

type isDecoded = boolean
const useTokenVerifier = (token?: string): [isDecoded, DecodedClient|null] => {
  try {
    if (token) {
      const decoded = jwt.decodeToken(token)
      return [isDecodedClient(decoded.payload), decoded]
    }
  } catch (error) {
    return [false, null]
  }

  return [false, null]
}

export default useTokenVerifier