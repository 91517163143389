import React from 'react'
import TextInput from './TextInput'
import useTokenVerifier from '../hooks/useTokenVerifier'
import { GlobalContext, UPDATE_ENVIRONMENT, SIGN_IN_USER, AvailableEnvironments } from '../state/reducer'

type Envs = 'local' | 'staging' | 'production'
const Login = () => {
  const { dispatch } = React.useContext(GlobalContext)
  const [token, setToken] = React.useState('')
  const [verified, decoded] = useTokenVerifier(token)
  const [env, setEnv] = React.useState<Envs>()

  React.useEffect(() => {
    if (verified && decoded && env) {
      dispatch({ type: UPDATE_ENVIRONMENT, data: env })
      dispatch({ type: SIGN_IN_USER, data: { token, client: decoded} })
    }
  }, [verified, decoded, token, dispatch, env])

  const environments: Envs[] = AvailableEnvironments.map(item => item.id)

  return (
    <div className="Login">
      <div>
        {environments.map(item => (
          <label>
            <input type="radio" onClick={() => setEnv(item)} />
            {item}
          </label>
        ))}
      </div>
      <TextInput label="API Key" enabled={true} value={token} onChange={setToken}/>
    </div>
  )
}

export default Login