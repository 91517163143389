import React from 'react'

type TextInputProps = {
  label: string
  value: string
  enabled?: boolean
  onChange: (val: string) => void
}

const TextInput: React.FC<TextInputProps> = ({ label, value, enabled, onChange }) => (
  <label>
    <span>{label}</span>
    <input type="text" disabled={!enabled} value={value} placeholder={label} onChange={e => onChange(e.target.value)} />
  </label>
)

export default TextInput