import React from 'react'
import TextInput from './TextInput'
import moment from 'moment'
import uuid from 'uuid'

export type DocketInputType = {
  guid: string
  destination_name: string
  docket_id: string
  driver_name: string
  harvester_name: string
  hauler_name: string
  product_name: string
  rego: string
  source_name: string
  supplier_name: string
  started_at: string
  started_at_location: string
  chain_of_responsibility_accepted?: boolean
  confirmed_at_timestamp: string
  metadata: { key: string, value: string }[]
}

const metadataCache = localStorage.getItem('DriverApp-metadata')

export const initialDocketState: DocketInputType = {
  guid: 'NEW-' + uuid.v4(),
  destination_name: localStorage.getItem('DriverApp-destination_name') || 'Mill ABC',
  docket_id: localStorage.getItem('DriverApp-docket_id') || 'H123456',
  driver_name: localStorage.getItem('DriverApp-driver_name') || 'John Smith',
  harvester_name: localStorage.getItem('DriverApp-harvester_name') || "John's Harvest Co.",
  hauler_name: localStorage.getItem('DriverApp-hauler_name') || "John's Haulage Co.",
  product_name: localStorage.getItem('DriverApp-product_name') || 'Pulp',
  rego: localStorage.getItem('DriverApp-rego') || 'TEST123',
  source_name: localStorage.getItem('DriverApp-source_name') || 'HFL Boomaroo Cmpt.4',
  supplier_name: localStorage.getItem('DriverApp-supplier_name') || 'ABC Forestry Management',
  started_at: localStorage.getItem('DriverApp-started_at') || moment().subtract(1, 'hour').format(),
  started_at_location: localStorage.getItem('DriverApp-started_at_location') || '[-35.790139, 148.182252]',
  chain_of_responsibility_accepted: localStorage.getItem('DriverApp-chain_of_responsibility_accepted') === 'true',
  confirmed_at_timestamp: localStorage.getItem('DriverApp-confirmed_at_timestamp') || moment().subtract(1, 'hour').format(),
  metadata: metadataCache ? JSON.parse(metadataCache) : [{ key: 'MillDA', value: '123456' }]
}

const DocketCardReducer = (prev: DocketInputType, next: { key: string, value: any }): DocketInputType => {
  const { key, value } = next

  if (key === 'metadata') {
    localStorage.setItem(`DriverApp-${key}`, JSON.stringify(value))
    return {
      ...prev,
      metadata: [value]
    }
  }

  if (key === 'chain_of_responsibility_accepted') {
    localStorage.setItem(`DriverApp-${key}`, JSON.stringify(value))

    return {
      ...prev,
      chain_of_responsibility_accepted: value
    }
  }

  if (typeof value === 'string') {
    localStorage.setItem(`DriverApp-${key}`, value)
  }

  return {
    ...prev,
    [key]: value,
  }
}


const DocketCard = ({ onChange, enabled }: { onChange: (docket: DocketInputType) => void, enabled: boolean }) => {
  const [state, dispatch] = React.useReducer(DocketCardReducer, initialDocketState)
  React.useEffect(() => onChange(state), [onChange, state])

  const {
    destination_name,
    docket_id,
    driver_name,
    harvester_name,
    hauler_name,
    product_name,
    rego,
    source_name,
    supplier_name,
    started_at,
    metadata
  } = state

  const millDa = { key: 'MillDA', value: metadata[0].value }

  return (
    <div className="DocketCard">
      <TextInput enabled={enabled} label="Docket Id" value={docket_id} onChange={value => dispatch({ key: 'docket_id', value })} />
      <TextInput enabled={enabled} label="Supplier" value={supplier_name} onChange={value => dispatch({ key: 'supplier_name', value })} />
      <TextInput enabled={enabled} label="Source" value={source_name} onChange={value => dispatch({ key: 'source_name', value })} />
      <TextInput enabled={enabled} label="Destination" value={destination_name} onChange={value => dispatch({ key: 'destination_name', value })} />
      <TextInput enabled={enabled} label="Product" value={product_name} onChange={value => dispatch({ key: 'product_name', value })} />
      <TextInput enabled={enabled} label="Harvester" value={harvester_name} onChange={value => dispatch({ key: 'harvester_name', value })} />
      <TextInput enabled={enabled} label="Hauler" value={hauler_name} onChange={value => dispatch({ key: 'hauler_name', value })} />
      <TextInput enabled={enabled} label="Driver" value={driver_name} onChange={value => dispatch({ key: 'driver_name', value })} />
      <TextInput enabled={enabled} label="Driver Rego" value={rego} onChange={value => dispatch({ key: 'rego', value })} />
      <TextInput enabled={enabled} label="Started At" value={started_at} onChange={value => dispatch({ key: 'started_at', value })} />
      <TextInput enabled={enabled} label="MillDA" value={millDa.value} onChange={value => dispatch({ key: 'metadata', value: { ...millDa, value } })} />
    </div>
  )
}

export default DocketCard