import React from 'react'
type useLocalStorageFunc = <T>(key: string, defaultValue: T) => [
  T,
  (value: any) => void
]

const getValue = (key: string, defaultValue: any) => {
  const cache = localStorage.getItem(key)

  if (cache) {
    // If defaultValue is string, then no parsing required.
    if (typeof defaultValue === 'string') {
      return cache
    } else {
      return JSON.parse(cache)
    }
  }

  return defaultValue
}
const useLocalStorage: useLocalStorageFunc = (key, defaultValue) => {
  const [value, setValue] = React.useState<any>(getValue(key, defaultValue))

  React.useEffect(() => setValue(getValue(key, defaultValue)), [key, defaultValue])

  return [
    typeof defaultValue === 'string' ? value : JSON.parse(value),
    (val: any) => {
      if (typeof defaultValue === 'string') {
        setValue(val)
        localStorage.setItem(key, val)
      } else {
        setValue(val)
        localStorage.setItem(key, JSON.stringify(val))
      }
    },
  ]
}

export default useLocalStorage