import React from 'react'

export enum ReadyState {
  Idle = -1,
  Connecting = 0,
  Open = 1,
  Closing = 2,
  Closed = 3,
}

type SendMessageFunction = (payload: any) => void
type DisconnectFunction = () => void
type ReconnectFunction = () => void

export const isConnected = (readyState: ReadyState): boolean => readyState === ReadyState.Open

export const getReadyStateName = (readyState: ReadyState): string => {
  return {
    [ReadyState.Idle]: 'Closed (Idle)',
    [ReadyState.Connecting]: 'Connecting',
    [ReadyState.Open]: 'Open',
    [ReadyState.Closing]: 'Closing',
    [ReadyState.Closed]: 'Closed',
  }[readyState]
}

export type AirServerEvent = { type: string, data: any }

type useAirSocketFunction = (
  url: string, 
  token?: string,
  options?: { onClose?: () => void, onOpen?: () => void }
) => [SendMessageFunction, AirServerEvent | undefined, ReadyState, DisconnectFunction, ReconnectFunction, string[], string | null, Error ?]

const useAirSocket: useAirSocketFunction = (url, token, options) => {
  const socket = React.useRef<WebSocket>()
  const [error, setError] = React.useState<Error>()
  const [logs, appendLog] = React.useReducer((prev: string[], next: string): string[] => [...prev, next], [])
  const [readyState, setReadyState] = React.useState<number>(ReadyState.Idle)
  const [parsedMessage, setParsedMessage] = React.useState()
  const [version, setVersion] = React.useState<string|null>(null)
  const [reconnectTicker, setReconnectTicker] = React.useState(Date.now())

  const sendMessage = React.useCallback((payload: any) => {
    const msg: string = typeof payload === 'string' ? payload : JSON.stringify(payload)

    if (readyState === ReadyState.Open) {
      if (process.env.NODE_ENV === 'development') {
        console.log('SEND', JSON.parse(msg))
      }
     
      if (socket.current) {
        socket.current.send(msg)
      }
      
      appendLog(msg)
    }
  }, [readyState, socket])

  React.useEffect(() => {
    if (url === '') {
      return
    }


    if (!token) {
      return
    }

    try {
      socket.current = new WebSocket(url)

      socket.current.addEventListener('message', (msg: MessageEvent) => {
        const parsed = JSON.parse(msg.data)
        setParsedMessage(parsed)
        appendLog(msg.data)

        if (parsed.type === 'version') {
          setVersion(parsed.data.version)
        }
      })

      socket.current.addEventListener('open', () => {
        setReadyState(ReadyState.Open)
        appendLog(JSON.stringify({ type: 'connected', data: null }))

        if (socket.current) {
          socket.current.send(JSON.stringify({ type: 'version', data: null }))
          socket.current.send(JSON.stringify({ type: 'authenticate', data: { token }}))
        }
      })

      socket.current.addEventListener('close', () => {
        setReadyState(ReadyState.Idle)
        socket.current = undefined
        appendLog(JSON.stringify({ type: 'disconnected' , data: null }))
      })
  
      if (options) {
        if (options.onOpen) {
          socket.current.addEventListener('open', options.onOpen)
        }
         
        if (options.onClose) {
          socket.current.addEventListener('close', options.onClose)
        }
      }

      setReadyState(ReadyState.Connecting)
  
      return () => {
        socket.current && socket.current.close()
        socket.current = undefined
      }
    } catch (error) {
      setError(error)
      setReadyState(ReadyState.Idle)
    }
  }, [url, options, token, reconnectTicker])

  const disconnect = () => {
    if (socket.current) {
      socket.current.close()
      setReadyState(ReadyState.Idle)
    }
  }

  const reconnect = () => {
    setReconnectTicker(Date.now())
  }

  return [sendMessage, parsedMessage, readyState, disconnect, reconnect, logs, version, error]
}

export const AirSocketStatus = ({ status }: { status: number }) => {
  const success = [ReadyState.Connecting, ReadyState.Open]
  const warning = [ReadyState.Closing, ReadyState.Idle]
  const danger = [ReadyState.Closed]
  
  const getColor = (statusNumber: number) => {
    if (success.includes(statusNumber))
      return 'success'
    
    if (warning.includes(statusNumber))
      return 'warning'
    
    if (danger.includes(statusNumber))
      return 'danger'
  }

  const color = getColor(status)

  return (
    <span className={`tag ${color}`}>Socket: {getReadyStateName(status)}</span>
  )
}

export default useAirSocket