import React from 'react'
import { GlobalContext, UPDATE_DALLAS_KEY_NUMBER_PLATE } from '../state/reducer'
import ApiKeyStatus from './ApiKeyStatus'
import { GenericSocketLogs } from './SocketLogs'
import useAirSocket, { AirSocketStatus, AirServerEvent } from '../hooks/useAirSocket'
import { AirChannel, IdentityReceived, AllActionTypes, IdentityChannel } from 'logr-air-lib'
import moment from 'moment'
import { PrettyLogItem, GenericAction } from '../state/types'
import useLocalStorage from '../hooks/useLocalStorage'
import useDebounce from '../hooks/useDebounce'

const DallasKey = () => {
  const { state, dispatch } = React.useContext(GlobalContext)
  const { dallasKey, weighbridgeUid, host } = state
  const { apiKey, numberPlate: numberPlateStore } = dallasKey
  const url = [host, weighbridgeUid].join('/')
  const [sendMessage, lastEvent, readyState] = useAirSocket(url, apiKey)
  const [prettyLogs, addPrettyLog] = React.useReducer((state: PrettyLogItem[], action: GenericAction): PrettyLogItem[] => [{ iat: Date.now(), evt: action }, ...state], [])
  const [inputType, setInputType] = useLocalStorage(`DallasKey-whitelist`, 'whitelist')
  const setInputTypeWhitelist = React.useCallback(() => setInputType('whitelist'), [setInputType])
  const setInputTypeRaw = React.useCallback(() => setInputType('raw'), [setInputType])

  const [value, setValue] = useLocalStorage(`DallasKey-number-plate`, 'TESTREGO')
  const debouncedValue = useDebounce(value, 500)
  React.useEffect(() => dispatch({ type: UPDATE_DALLAS_KEY_NUMBER_PLATE, data: debouncedValue }), [debouncedValue, dispatch])

  const [currentEvent, dispatchEvent] = React.useReducer((prev: AirServerEvent | undefined, triggerEvent: AirServerEvent): AirServerEvent | undefined => {
    if (numberPlateStore === undefined || numberPlateStore === '') {
      return prev
    }

    if (prev?.type !== triggerEvent?.type) {
      switch (triggerEvent?.type) {
        case AirChannel.requested_identity:
          if (inputType === 'whitelist') {
            const identity: IdentityReceived = {
              request_id: triggerEvent.data.uid,
              identities: [
                {
                  source: 'rego',
                  value: `${numberPlateStore}`,
                  references: [{
                    type: 'dallas_key',
                    value: '01-00001aee3c9a'
                  }]
                }
              ]
            }

            const payloadSent: AllActionTypes = {
              type: IdentityChannel.identity_received,
              data: identity
            }

            sendMessage(JSON.stringify(payloadSent))
            addPrettyLog(triggerEvent)
            addPrettyLog(payloadSent)
          }

          if (inputType === 'raw') {
            const identity: IdentityReceived = {
              request_id: triggerEvent.data.uid,
              identities: [
                {
                  source: 'dallas',
                  value: '01-00001aee3c9a',
                }
              ]
            }

            const payloadSent: AllActionTypes = {
              type: IdentityChannel.identity_received,
              data: identity
            }

            sendMessage(JSON.stringify(payloadSent))
            addPrettyLog(triggerEvent)
            addPrettyLog(payloadSent)
          }


          break
      }
    }

    return prev
  }, undefined)

  React.useEffect(() => lastEvent && dispatchEvent(lastEvent), [lastEvent, numberPlateStore])

  return (
    <div className="DallasKey">
      <h3>Rego ID</h3>

      <div className="TagsContainer">
        <AirSocketStatus status={readyState} />
        <ApiKeyStatus apiKey={apiKey} />
      </div>

      <div className="InputTypeSelector">
        <label>
          <input type="radio" checked={inputType === 'whitelist'} onChange={setInputTypeWhitelist} />
          <span>Dallas Key (Whitelisted)</span>
        </label>
        <label>
          <input type="radio" checked={inputType === 'raw'} onChange={setInputTypeRaw} />
          <span>Dallas Key Raw</span>
        </label>
      </div>

      <GenericSocketLogs>
        {prettyLogs.map(({ iat, evt }, index) => {
          const issuedAt = <span style={{ fontSize: 6 }}>{moment(iat).fromNow()}</span>
          switch (evt.type) {
            case AirChannel.requested_identity:
              return <p key={index}>RECV: Identity Request {issuedAt}</p>
            case IdentityChannel.identity_received:
              return <p key={index}>SENT: [{evt?.data?.identities[0]?.source}] {evt?.data?.identities[0]?.value} {issuedAt}</p>
          }

          return null
        })}
      </GenericSocketLogs>

      <input
        type="text"
        value={value}
        size={12}
        onChange={e => setValue(e.target.value)}
        placeholder="rego"
        className="NumberPlate"
      />

    </div>
  )
}

export default DallasKey