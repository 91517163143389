import React from 'react'
import './reset.css'
import './App.css'
import Configuration from './components/Configuration'
// import AnprCamera from './components/AnprCamera'
import DriverApp from './components/DriverApp'
import Weighbridge from './components/Weighbridge'
import CloudLog from './components/CloudLog'
import Login from './components/Login'
import { GlobalContext, reducer, initialState, TOGGLE_SHOW_WEIGHBRIDGE, TOGGLE_SHOW_DRIVER_APP, TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE } from './state/reducer'
import DallasKey from './components/DallasKey'

const App: React.FC = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { weighbridge, driverApp, dallasKey } = state
  const showWeighbridge = weighbridge.show
  const showDriverApp = driverApp.show
  const showDallasKey = dallasKey.show

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      <div className="App">
        {!weighbridge.apiKey && (
          <>
            <header className="App-header">
              <h1>{window.document.title}</h1>
            </header>
            <main>
              <Login />
            </main>
          </>
        )}
        {weighbridge.apiKey && (
          <>
            <header className="App-header">
            <h1>{window.document.title}</h1>
              <Configuration />
            </header>
            <div>
              <button onClick={() => dispatch({ type: TOGGLE_SHOW_WEIGHBRIDGE })}>Toggle Weighbridge</button>
              <button onClick={() => dispatch({ type: TOGGLE_SHOW_DRIVER_APP })}>Toggle Driver App</button>
              <button onClick={() => dispatch({ type: TOGGLE_SHOW_DALLAS_KEY_IDENTITY_SERVICE })}>Toggle REGO</button>
            </div>
            <main>
              {showWeighbridge && <Weighbridge />}
              {/* <AnprCamera /> */}
              {showDriverApp && <DriverApp />}
              {showDallasKey && <DallasKey />}
              {/* <CloudLog /> */}
            </main>
          </>
        )}
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
