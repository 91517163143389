import React from 'react'
import { GlobalContext, NEW_SESSION } from '../state/reducer'
import ApiKeyStatus from './ApiKeyStatus'
import useAirSocket, { AirSocketStatus, AirServerEvent } from '../hooks/useAirSocket'
import { AirAuthChannel, WeighbridgeChannel, AirChannel, WeightMeasurement, DriverClaimAndIdentityConfirmed, Receipt, ReceivedStableWeight } from 'logr-air-lib'
import uuid from 'uuid'
import moment from 'moment'
import SocketLogs from './SocketLogs'
import { PrettyLogItem, GenericAction } from '../state/types'
import useLocalStorage from '../hooks/useLocalStorage'
import useDebounce from '../hooks/useDebounce'
import PrettyLogs from './PrettyLogs'
import useTokenVerifier from '../hooks/useTokenVerifier'

type MeasurementType = 'unknown' | 'gross' | 'tare'
type WeightInputProps = {
  type: MeasurementType
  unit: 't' | 'kg'
  onChange: (value: string) => void
}
const MeasurementInput: React.FC<WeightInputProps> = ({ type, unit, onChange }) => {
  const [value, setValue] = useLocalStorage(`WeightInput-${type}`, '0')
  const debouncedValue = useDebounce(value, 500)
  React.useEffect(() => onChange(debouncedValue), [debouncedValue, onChange])

  return (
    <div className="MeasurementInput">
      <span>{type === 'unknown' ? 'weight' : type}</span>

      <div className="DisplayContainer">
        <input
          type="text"
          value={value || ''}
          size={7}
          className="ControlWeightIndicator"
          onChange={e => setValue(e.target.value)}
        />
        {unit}
      </div>
    </div>
  )
}

type WeighbridgeState = {
  authenticated: boolean
  confirmation?: DriverClaimAndIdentityConfirmed
  stableWeight?: ReceivedStableWeight
  receipt?: Receipt
}
const reducer = (state: WeighbridgeState, next: AirServerEvent | null): WeighbridgeState => {
  if (next === null) {
    return {
      ...state,
      confirmation: undefined,
      stableWeight: undefined,
      receipt: undefined
    }
  }
  switch (next?.type) {
    case AirChannel.claim_completed:
      return {
        ...state,
        confirmation: next.data,
        stableWeight: undefined
      }

    case AirAuthChannel.authenticate_confirmed:
      return {
        ...state,
        authenticated: true,
        stableWeight: undefined,
        confirmation: undefined
      }

    case WeighbridgeChannel.received_stable_weight:
      return {
        ...state,
        stableWeight: next.data,
        confirmation: undefined
      }

    case WeighbridgeChannel.created_receipt:
      return {
        ...state,
        confirmation: undefined,
        receipt: next.data
      }
  }

  return state
}

const measurementInputsReducer = (prev: WeightMeasurement[], next: WeightMeasurement) => {
  if (next.type === 'unknown') {
    return [...prev.filter(item => item.type !== 'unknown'), next]
  }

  return [...prev.filter(item => item.type !== next.type && item.type !== 'unknown'), next]
}

const Weighbridge = () => {
  const { state, dispatch } = React.useContext(GlobalContext)
  const { apiKey } = state.weighbridge
  const [verified] = useTokenVerifier(apiKey)
  const { weighbridgeUid, host } = state
  const url = [host, weighbridgeUid].join('/')
  const [sendMessage, lastEvent, readyState, disconnect, reconnect, logs] = useAirSocket(url, apiKey)
  const [prettyLogs, addPrettyLog] = React.useReducer((state: PrettyLogItem[], action: GenericAction | null): PrettyLogItem[] => action === null ? [] : [{ iat: Date.now(), evt: action }, ...state], [])
  React.useEffect(() => lastEvent && addPrettyLog(lastEvent), [lastEvent])
  const [measurements, updateMeasurement] = React.useReducer(measurementInputsReducer, [])
  const [inputType, setInputType] = useLocalStorage<'general' | 'gross_tare'>(`Weighbridge-InputType`, 'general')
  const [includeNet, setIncludeNet] = useLocalStorage('Weighbridge-IncludeNet', false)

  const [internalState, dispatchInternalAction] = React.useReducer(reducer, { authenticated: false })
  const { confirmation, stableWeight, receipt, authenticated } = internalState
  React.useEffect(() => lastEvent && dispatchInternalAction(lastEvent), [lastEvent])

  const [unit, setUnit] = useLocalStorage<'t' | 'kg'>(`Weighbridge-UnitType`, 't')

  const updateUnknown = React.useCallback(weight => updateMeasurement({ weight, unit, type: 'unknown' }), [unit])
  const updateGross = React.useCallback(weight => updateMeasurement({ weight, unit, type: 'gross' }), [unit])
  const updateTare = React.useCallback(weight => updateMeasurement({ weight, unit, type: 'tare' }), [unit])
  const selectGeneral = React.useCallback(() => setInputType('general'), [setInputType])
  const selectGrossTare = React.useCallback(() => setInputType('gross_tare'), [setInputType])

  const [rego, setRego] = React.useState(localStorage.getItem('Weighbridge-rego') || '')
  React.useEffect(() => localStorage.setItem('Weighbridge-rego', rego), [rego])

  const [customerReferenceNumber, setCustomerReferenceNumber] = React.useState(localStorage.getItem('Weighbridge-customerReferenceNumber') || '')
  React.useEffect(() => localStorage.setItem('Weighbridge-customerReferenceNumber', customerReferenceNumber), [customerReferenceNumber])

  const [done, setDone] = React.useState(false)

  const onSendReceipt = React.useCallback(() => {
    if (confirmation) {
      const payload: AirServerEvent = {
        type: WeighbridgeChannel.created_receipt,
        data: {
          receipt_id: uuid(),
          receipt_weights: [],
        }
      }

      confirmation.measurements.forEach(item => {
        payload.data.receipt_weights.push({
          measure_id: confirmation.measure_id,
          measure_at: confirmation.measure_at,
          measurements: [item]
        })
      })

      const grossMeasurement = confirmation.measurements.find(item => item.type === 'gross')
      const tareMeasurement = confirmation.measurements.find(item => item.type === 'tare')

      if (grossMeasurement && tareMeasurement && includeNet) {
        payload.data.receipt_weights.push({
          measure_id: uuid.v4(),
          measure_at: `test`,
          measurements: [{
            weight: (parseFloat(grossMeasurement.weight) - parseFloat(tareMeasurement.weight)).toFixed(4),
            unit: grossMeasurement.unit,
            type: 'net'
          }] 
        })
      }

      if (customerReferenceNumber && customerReferenceNumber.length !== 0) {
        payload.data.metadata = [{ key: 'customer_reference_number', value: customerReferenceNumber }]
      }

      dispatchInternalAction(payload)
      sendMessage(payload)
      addPrettyLog(payload)
      setDone(true)
    }
  }, [confirmation, customerReferenceNumber, sendMessage])

  const sendWeight = React.useCallback(() => {
    const measurementsForSubmission = measurements.filter(item => parseFloat(item.weight) !== 0)

    if (measurementsForSubmission.length === 0) {
      console.error("Must contain at least one measurement greater than 0 must be submitted.")
      return
    }

    let identities: any[] = []

    if (rego.trim().length > 0) {
      identities = [{ source: 'visy_rego', value: rego }]
    }

    const payload: { type: string, data: ReceivedStableWeight } = {
      type: WeighbridgeChannel.received_stable_weight,
      data: {
        measure_id: uuid(),
        measure_at: moment().format(),
        measurements: measurementsForSubmission,
        identities,
      }
    }

    dispatchInternalAction(payload)
    sendMessage(payload)
    addPrettyLog(payload)
  }, [measurements, sendMessage, rego])

  const isEnabled = authenticated && readyState === WebSocket.OPEN && verified
  const isIWeigh = weighbridgeUid === 'dDv4U0iok5OWbpxm4pXSVqC1O4E2' || weighbridgeUid === 'J9oP7Y32AjTLaAbH20uotRlUzxD2'

  return (
    <div className="Weighbridge">
      <h3>Weighbridge</h3>
      <div className="TagsContainer">
        <AirSocketStatus status={readyState} />
        <ApiKeyStatus apiKey={apiKey} />
      </div>

      {done && <span>Done</span>}
      {!done && isEnabled && (
        <>
          <div className="InputTypeSelector">
            <label>
              <input type="radio" checked={inputType === 'general'} onChange={selectGeneral} />
              <span>General Weight only</span>
            </label>
            <label>
              <input type="radio" checked={inputType === 'gross_tare'} onChange={selectGrossTare} />
              <span>Gross / Tare</span>
            </label>
          </div>
          <div className="InputTypeSelector">
            <label>
              <input type="radio" checked={unit === 't'} onChange={() => setUnit('t')} />
              <span>Unit: tonnes</span>
            </label>
            <label>
              <input type="radio" checked={unit === 'kg'} onChange={() => setUnit('kg')} />
              <span>Unit: kg</span>
            </label>
          </div>
          <div>
            <label>Include net in receipt</label>
            <input type="checkbox" checked={includeNet} onChange={e => setIncludeNet(e.target.checked)}/>
          </div>

          <div className="MeasurementInputContainer">
            {inputType === 'general' && <MeasurementInput type="unknown" unit={unit} onChange={updateUnknown} />}
            {inputType === 'gross_tare' && <MeasurementInput type="gross" unit={unit} onChange={updateGross} />}
            {inputType === 'gross_tare' && <MeasurementInput type="tare" unit={unit} onChange={updateTare} />}
            {!isIWeigh && <input type="text" placeholder="rego" value={rego} onChange={e => setRego(e.target.value)} />}
            {!isIWeigh && <input type="text" placeholder="customer reference number" value={customerReferenceNumber} onChange={e => setCustomerReferenceNumber(e.target.value)} />}
          </div>

          <div className="ButtonControls">
            {!stableWeight && !confirmation && !receipt && <button className="button" onClick={sendWeight}>Send Weight</button>}
            {confirmation && <button className="button" onClick={onSendReceipt}>Send Receipt</button>}
            {receipt && <button className="button" onClick={onSendReceipt}>Send Receipt</button>}
            {/* <button className="button" onClick={() => {
              updateMeasurement({ weight: '0', unit: 't', type: 'unknown' })
              updateMeasurement({ weight: '0', unit: 't', type: 'gross' })
              updateMeasurement({ weight: '0', unit: 't', type: 'tare' })
              addPrettyLog(null) // Resets Logs
              dispatchInternalAction(null) // Resets internal state
              dispatch({ type: NEW_SESSION })
              reconnect()
            }}>Zero</button> */}
          </div>
        </>
      )}
      <PrettyLogs logs={prettyLogs} />
      {window.location.href.indexOf('debug') > -1 && <SocketLogs logs={logs} />}
    </div>
  )
}

export default Weighbridge