import React from 'react'
import { PrettyLogItem } from '../state/types'
import moment from 'moment'
import { AirChannel, DriverChannel, WeighbridgeChannel } from 'logr-air-lib'
import yml from 'json-to-pretty-yaml'
import { GenericSocketLogs } from './SocketLogs'

const PrettyLogs = ({ logs }: { logs: PrettyLogItem[] }) => (
  <div>
    <GenericSocketLogs>
      {logs.map(({ iat, evt }, index) => {
        const issuedAt = <span style={{ fontSize: 6 }}>{moment(iat).fromNow()}</span>
        const data = yml.stringify(evt?.data)

        // Weighbridge
        switch (evt.type) {
          case WeighbridgeChannel.received_stable_weight:
            return <p key={index}>SENT: Stable Weight {issuedAt} <br />{data}</p>
          case AirChannel.claim_completed:
            return <p key={index}>RECV: Received Confirmation {issuedAt} <br />{data}</p>
          case WeighbridgeChannel.created_receipt:
            return <p key={index}>SENT: Receipt {issuedAt} <br />{data}</p>
        }

        // Driver App
        switch (evt.type) {
          case DriverChannel.driver_claimed:
            return <p key={index}>SEND: Claim {issuedAt} <br />{data}</p>

          case DriverChannel.driver_confirmed:
            return <p key={index}>SEND: Confirm {issuedAt} <br />{data}</p>

          case DriverChannel.driver_denied:
            return <p key={index}>SEND: Decline {issuedAt} <br />{data}</p>

          case AirChannel.claim_became_available:
            return <p key={index}>RECV: Claim {issuedAt} <br />{data}</p>

          case AirChannel.receipt_received:
            return <p key={index}>RECV: Receipt {issuedAt} <br />{data}</p>
        }

        return null
      })}
    </GenericSocketLogs>
  </div>
)

export default PrettyLogs