import React from 'react'
import useTokenVerifier from '../hooks/useTokenVerifier'

const ApiKeyStatus = ({ apiKey }: { apiKey?: string }) => {
  const keyProvided = apiKey && apiKey.trim().length > 0
  const [validToken] = useTokenVerifier(apiKey)
  const bad = []
  const good = []

  if (!keyProvided) {
    bad.push('Missing')
  }
  if (!validToken) {
    bad.push('Invalid')
  }

  if (validToken) {
    good.push('Valid')
  }

  return (
    <div className="ApiKeyStatus">
      {bad.length > 0 && (
        <span className="tag danger">
          API Key: {bad.join(", ")}
        </span>
      )}
      {good.length > 0 && (
        <span className="tag success">
          API Key: {good.join(", ")}
        </span>
      )}
    </div>
  )
}

export default ApiKeyStatus