import React from 'react'
import TextInput from './TextInput'
import {
  GlobalContext,
  UPDATE_CONTROL_API_KEY,
  UPDATE_CAMERA_API_KEY,
  UPDATE_DRIVER_API_KEY,
  UPDATE_ENVIRONMENT,
  UPDATE_WEIGHBRIDGE_UID,
  AvailableEnvironments,
  Uid,
  UPDATE_DALLAS_KEY_API_KEY
} from '../state/reducer'
import useTokenVerifier from '../hooks/useTokenVerifier'

const Configuration = () => {
  const { state, dispatch } = React.useContext(GlobalContext)
  const { weighbridgeUid, weighbridge, camera, driverApp, dallasKey, environment } = state
  const onChangeControlApi = (val: string) => dispatch({ type: UPDATE_CONTROL_API_KEY, data: val })
  const onChangeCameraApi = (val: string) => dispatch({ type: UPDATE_CAMERA_API_KEY, data: val })
  const onChangeDriverApi = (val: string) => dispatch({ type: UPDATE_DRIVER_API_KEY, data: val })
  const onChangeDallasKeyApi = (val: string) => dispatch({ type: UPDATE_DALLAS_KEY_API_KEY, data: val })
  const onChangeUid = (val: string) => dispatch({ type: UPDATE_WEIGHBRIDGE_UID, data: val })
  const onChangeEnvironment = (env: 'local' | 'staging' | 'production') => dispatch({ type: UPDATE_ENVIRONMENT, data: env })
  const [verified, client] = useTokenVerifier(weighbridge.apiKey)

  return (
    <div className="Configuration">
      <div className="HorizontalRadios">
        {AvailableEnvironments.map(({ id, name, url }) => (
          <label key={id}>
            <input type="radio" value={id} checked={environment === id} onChange={() => onChangeEnvironment(id)} />
            <span>{name} ({url})</span>
          </label>
        ))}
      </div>
      <TextInput label="Weighbridge UID" enabled={true} value={weighbridgeUid || ''} onChange={onChangeUid} />
      <TextInput label="Weighbridge Api Key" enabled={true} value={weighbridge.apiKey || ''} onChange={onChangeControlApi} />
      {verified && (client?.uid === Uid.iWeigh || client?.uid === Uid.enabledUid) && (
        <TextInput label="Camera Api Key" enabled={true} value={camera.apiKey || ''} onChange={onChangeCameraApi}/>
      )}
      <TextInput label="Driver Api Key" enabled={true} value={driverApp.apiKey || ''} onChange={onChangeDriverApi}/>
      <TextInput label="Rego ID Api Key" enabled={true} value={dallasKey.apiKey || ''} onChange={onChangeDallasKeyApi}/>
    </div>
  )
}

export default Configuration