import React from 'react'
import YAML from 'json-to-pretty-yaml'

const SocketLogs = ({ logs }: { logs: string[] }) => {
  const [show, setShow] = React.useState(true)

  return (
    <div className="SocketLogs">
      <div>
        <h1>Socket Logs</h1>
        <span onClick={() => setShow(!show)}>{show ? 'hide' : 'show'}</span>
      </div>
      {show && logs.map((item, index) => (
        <p key={index}>
          {YAML.stringify(JSON.parse(item))}
        </p>
      ))}
    </div>
  )
}

export const GenericSocketLogs: React.FC = ({ children }) => {
  const [show, setShow] = React.useState(true)

  return (
    <div className="SocketLogs GenericSocketLogs">
      <div>
        <h1>Logs</h1>
        <span onClick={() => setShow(!show)}>{show ? 'hide' : 'show'}</span>
      </div>
      {show && children}
    </div>
  )
}

export default SocketLogs